import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Eventos1 from './components/Eventos';


export default function Eventos() {
  return (
    <>
      <Header />
      <div className='py-10 bg-[#f5f5f5]'></div>
      <Eventos1 />
      <Footer />
    </>
  );
}