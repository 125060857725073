import { useEffect, useState } from "react"

export default function TerapiaIntegrativa() {

  const [troca, setTroca] = useState(1)

  useEffect(() => {
    const interval = setTimeout(() => {
      if (troca === 1) {
        console.log("entoru 1")
        setTroca(2)
      } else {
        console.log("entoru 2")
        setTroca(1)
      }
    }, 5000); // 3000 milissegundos = 3 segundos

    // Limpando o intervalo ao desmontar o componente
    return () => clearTimeout(interval);
  }, [troca]);

  return (
    <div className="w-full bg-[#f5f5f5]">
      <div className="grid lg:grid-cols-2 py-[124px] 2xl:w-[1568px] w-[90%] mx-auto ">
        <div className="flex flex-col justify-center">
          <h2 className="text-[56px] font-black  text-[#502274] leading-[58px] mb-6">
            TERAPIAS INTEGRATIVAS: <span className="font-semibold">  O SEGREDO PARA O ALCANCE DA FELICIDADE PLENA </span>
          </h2>
          <p className="text-[20px]  text-[#502274] mb-8">
            O segredo do alcance da felicidade plena está em buscar o equilíbrio em todas as áreas da vida terrena e espiritual, ou seja, buscar a
            felicidade 100%. Comigo, você tem a possibilidade de alcançar a plenitude e ser FELIZ.
            Você pode (e deve) vivenciar a felicidade como uma constante progressiva. Se algo não está bem hoje, e você não se sente feliz, é
            possível fazer alguma coisa, para que sua felicidade seja integral em sua vida atual, com equilíbrio e harmonia.
            Nas Terapias Integrativas, temos diversas técnicas e recursos terapêuticos usados por meio de métodos naturais, que buscam melhorar a
            saúde e o bem-estar físico e emocional, mediante a adoção de um estilo de vida mais saudável e harmônico.

          </p>
          <a href="#contato"  >
            <div className="w-fit px-6 h-[64px] flex justify-center items-center rounded-full bg-[#A83F97] text-[1.25rem] text-[#fff]" >
              FAÇA TERAPIA INTEGRATIVA
            </div>
          </a>
        </div>
        <div className="sm:flex hidden items-center justify-center md:m-auto relative flex-wrap gap-4 w-[520px] mt-[42px] " >
          <div className="min-w-[302px] h-[206px] rounded-lg transition-all duration-1000 overflow-hidden bg-cover" style={{ minWidth: troca === 1 ? "302px" : "165px", backgroundImage: `url(${troca === 1 ? "/3.jpg" : "/13.jpg"})` }}>

          </div>
          <div className="min-w-[154px] h-[206px] rounded-lg transition-all duration-1000 overflow-hidden bg-cover" style={{ minWidth: troca === 1 ? "154px" : "302px", backgroundImage: `url(${troca === 1 ? "/15.jpg" : "/27.jpg"})` }}>

          </div>
          <div className="min-w-[154px] h-[206px] rounded-lg transition-all duration-1000 overflow-hidden bg-cover" style={{ minWidth: troca === 1 ? "154px" : "302px", backgroundImage: `url(${troca === 1 ? "/29.jpg" : "/3.jpg"})` }}>

          </div>
          <div className="min-w-[302px] h-[206px] rounded-lg transition-all duration-1000 overflow-hidden bg-cover" style={{ minWidth: troca === 1 ? "302px" : "154px", backgroundImage: `url(${troca === 1 ? "/27.jpg" : "/2.jpg"})` }}>

          </div>
        </div>
      </div>

    </div>
  )
}