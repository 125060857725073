

export default function Contato1() {

  return (
    <div className="w-full bg-[#f5f5f5]">
      <div id="contato" className="grid lg:grid-cols-2 py-[124px] 2xl:max-w-[1568px] w-[90%] mx-auto bg-[#f5f5f5]">
        <div className="flex flex-col justify-center">
          <h2 className="text-[88px] font-black text-[#502274] leading-[58px] mb-8">
            Entre em contato
          </h2>
          <form action="" className="flex flex-col sm:flex-row  mb-[32px]">
            <label htmlFor="email w-0"></label>
            <input type="email" name="email" id="email" placeholder="digite seu email" className="border-[1px] rounded w-[250px] h-[62px] p-[12px] mr-[12px] " />
            <button className="w-[192px] h-[64px] flex justify-center items-center rounded-full bg-[#E9C0E9] text-[1.25rem] text-[#000] mt-[16px] sm:mt-0" >
              Confirmar
            </button>
          </form>
          <p className="text-[36px] font-bold text-[#502274] mb-6">
            siga na redes sociais
          </p>
          <div className="flex gap-[8px]">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
              className="w-[64px] h-[64px] rounded-[50%] hover:bg-[#4267B2]"
            >
              <span >
                <img
                  src="/facebook.svg"
                  alt="Visitar Facebook"
                  width={64}
                  height={64}
                />
              </span>
            </a>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
              className="w-[64px] h-[64px] rounded-[50%] hover:bg-[#C13584]"
            >
              <span >
                <img
                  src="/instagram.svg"
                  alt="Visitar Instagram"
                  width={64}
                  height={64}
                />
              </span>
            </a>
            <a
              href="https://www.facebook.com/l"
              target="_blank"
              rel="noreferrer"
              className="w-[64px] h-[64px] rounded-[50%] hover:bg-[#1DA1F2]"
            >
              <span >
                <img
                  src="/twitter.svg"
                  alt="Visitar Twitter"
                  width={64}
                  height={64}
                />
              </span>
            </a>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
              className="w-[64px] h-[64px] hover:bg-[#ff0000] rounded-[50%]"
            >
              <span className="w-full h-full">
                <img
                  src="/youtube.svg"
                  alt="Visitar YouTube"
                  width={64}
                  height={64}
                />
              </span>
            </a>
          </div>

        </div>
        <div className="flex items-center justify-center w-full relative lg:m-0 mt-[42px]">
          <img src="/31.jpg" className="2xl:h-[518px] 2xl:w-[336px] md:h-[500px] w-[220px] h-[460px] md:w-[280px] bg-[#E9C0E9] rounded-2xl z-10" />
        </div>
      </div>
    </div>
  )
}