import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import QuebraSessao from './components/Quebra';
import Quebra2 from './components/Quebra2';
import Radiestesia from './components/Radiestesia';
import SessaoSimpleDireita from './components/Sessao-simples-direita';
import SessaoSimplesEsquerda from './components/Sessao-simples-esquerda';
import TerapiaIntegrativa from './components/Terapia-integrativa';

export default function Terapias() {
  return (
    <>
      <Header />
      <div className='py-10 bg-[#f5f5f5]'></div>
      <TerapiaIntegrativa />
      {/* <Formacao /> */}
      <SessaoSimplesEsquerda
        img={'/35.jpg'}
        titulo="O QUE É APOMETRIA?"
        bg="#502274"
        texto="A palavra apometria vem do grego: “apo” = “além de” + “metron” = “medida”.
A Apometria surgiu em 1965, em Porto Alegre, a partir de estudos do doutor Luiz Rodrigues, médico de origem porto-riquenha, associados
à Hipnometria: uma técnica de desdobramento realizada por meio de pulsos magnéticos, com comando verbal e o estalar dos dedos.
Posteriormente, o Doutor José Lacerda de Azevedo e sua esposa, Iolanda Lacerda de Azevedo, continuaram estes estudos, chegando a
publicar os seguintes livros: Espírito e Matéria: Novos horizontes para Medicina, em 1987; Energia e Espírito, em 1993. Desde então, várias
linhas da Apometria surgiram, tendo essa base e o uso das 14 leis apométricas.
Apometria é a medicina da alma, um tratamento que muda a sua vibração instantaneamente. É uma técnica cuja essência está na
capacidade de realizar desdobramentos por meio de pulsos (com contagem em voz alta, acompanhada ou não do estalar dos dedos). Os
comandos da Apometria permitem acessar os corpos sutis e as personalidades dessa e de outras vidas. A partir disso, muitas possibilidades
se abrem. É feita, então, uma limpeza energética, liberando-se traumas e crenças limitantes, seguida do protocolo de leis que rege a
Apometria."
      />
      
      <Quebra2 />
      <SessaoSimpleDireita
        img={'/39.jpg'}
        titulo="COMO ACONTECEM OS ATENDIMENTOS?"
        texto="Os atendimentos de Apometria Clínica são realizados por meio de consultas online ou presencial em consultório. Dou início ao tratamento
apométrico com uma anamnese, feita a partir de relatos de queixas do momento de vida do cliente. Daí, monto um programa de
atendimentos baseado nas ferramentas terapêuticas, definindo o número necessário de sessões."
      />
      <SessaoSimplesEsquerda
        img={'/22.jpeg'}
        titulo="ANTROPOLOGIA DA MULHER (RODA DE MULHERES)"
        texto="A Antropologia da Mulher é um estudo de formação, que me habilita a trabalhar com a Roda de Mulheres.
A Roda busca criar uma conexão entre mulheres, trazendo-lhes o autoconhecimento do sagrado feminino. O modelo circular é usado para
se fazer uma reconexão com as qualidades femininas perdidas. Nele, há colaboração, compaixão e apoio, de forma a propiciar a cura
emocional e o fortalecimento espiritual. Participar de uma Roda de Mulheres é uma forma de integrar razão e intuição. É uma oportunidade
de reconhecer a força existencial interna, que, muitas vezes, as mulheres não sabem que têm."
      />
      <QuebraSessao
        bg="#f5f5f5"
        titulo="RENASCIMENTO APOMÉTRICO"
        texto="É uma reprogramação de registros negativos, que estão profundamente enraizados no ser. São padrões ligados às relações familiares, aos
estados de solidão, à baixa autoestima, à depressão. É trabalhado pela projeção ao infinito, ao longo da linha do tempo do cliente: da
véspera de seu nascimento à data de seu nascimento, depois infância, adolescência, fase jovem e fase adulta. "
      />

      <SessaoSimpleDireita
        img={'/13.jpg'}
        titulo="CONTOTERAPIA"

        texto="A Contoterapia é uma ferramenta terapêutica, que consiste em utilizar contos da literatura mundial, para abordar diferentes questões ligadas
ao comportamento humano. Ela aguça a intuição e a imaginação. O conto dá conta! "
      />
      <SessaoSimplesEsquerda
        img={'/2.jpg'}
        titulo="CRISTALTERAPIA"
        texto="É uma modalidade terapêutica, que trabalha com a frequência cromática dos cristais, com a finalidade de promover equilíbrio de forma
física, mental, emocional e energética. Essa técnica é muito eficaz para o desbloqueio de energias mal qualificadas. Alivia as sensações
negativas."
      />
      <QuebraSessao
        bg="#f5f5f5"
        titulo="BARRA DE ACCESS "
        texto="A Barra de Access atua como uma terapia quântica, que promove o acesso energético e o equilíbrio entre o corpo e a mente, por meio de
gestos suaves. A facilitadora trabalha 32 pontos específicos, situados na cabeça. É indicada para a diminuição do estresse, o aumento da
energia e da disposição, a concentração, o controle do ataque de pânico e o déficit de atenção. "
      />

      <SessaoSimpleDireita
        img={'/16.jpg'}
        titulo="CROMOTERAPIA"
        texto="A Cromoterapia é um método no qual se utilizam as cores, com o objetivo de harmonizar o corpo. Por meio de um bastão cromático, limpase e energiza-se os chacras. Cada cor possui uma vibração específica e uma capacidade terapêutica, que auxiliam e promovem a saúde e
o bem-estar"
      />

      <SessaoSimplesEsquerda
        img={'/32.jpg'}
        titulo="HIPNOTERAPIA"
        texto="A Hipnoterapia, também chamada Hipnose Clínica, consiste na aplicação de técnicas hipnóticas como ferramenta terapêutica. Auxilia as
pessoas no manejo de problemas diários e na superação de transtornos psicológicos. "
      />
      <Radiestesia />
      <SessaoSimpleDireita
        img={'/19.jpeg'}
        titulo="HO’OPONOPONO"
        texto="É uma técnica havaiana de autocura, que atribui total responsabilidade a tudo o que acontece em nossas vidas, por meio do uso de
frases afirmativas, como “sinto muito”, “me perdoe”, “eu te amo”, “eu sou grato”, buscando promover o perdão, a reconciliação e a
transformação interior. Você pode fazer uso dessa técnica sempre, e limpar as memórias que atrasam ou dificultam a sua vida."
      />

      <SessaoSimplesEsquerda
        img={'/33.jpeg'}
        titulo="REGRESSÃO"
        texto="A regressão é um retorno no tempo e no espaço, independentemente de crenças pessoais ou de religião. O objetivo é ativar cargas
emocionais adormecidas, que podem motivar algum transtorno psicológico no presente, e ressignificar o valor dessas lembranças. A pessoa
fica consciente durante a prática, sendo induzida ao relaxamento por uma meditação guiada. "
      />
      <QuebraSessao
        bg="#f5f5f5"
        titulo="LIMPEZA DE AMBIENTE COM ORGONITE"
        texto="Os orgonites são equipamentos quânticos, que transformam a energia negativa em energia positiva. Têm o objetivo de retirar e transmutar
a energia estagnada em energia em movimento."
      />
      <SessaoSimpleDireita
        img={'/37.jpeg'}
        titulo="AROMATERAPIA"
        texto="É um tratamento feito por meio de cheiros agradáveis. São utilizados óleos essenciais 100% puros, que ajudam a lidar com distúrbios, como
dores e desconfortos, insônia e ansiedade, transtorno de humor e declínio cognitivo. Como funciona? Os óleos essenciais liberam partículas
que estimulam nossas células nervosas. Ao inalar seus aromas, nossos canais olfativos mandam um sinal para o sistema nervoso, que
reage modificando nosso humor ou estado de espírito."
      />
      <Footer />
    </>
  );
}