import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Eventos1 from './components/Eventos';
import Cursos from './components/Cursos';


export default function Casos() {
  return (
    <>
      <Header />
      <div className='py-12 bg-[#69346D]'></div>
      <Cursos />
      <Footer />
    </>
  );
}