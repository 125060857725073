import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Contato1 from './components/Contato';


export default function Contato() {
  return (
    <>
      <Header />
      <div className='py-10 bg-[#f5f5f5]'></div>
      <Contato1 />
      <Footer />
    </>
  );
}