import './App.css';

import Footer from './components/Footer';
import Header from './components/Header';
import SessaoSimpleDireita from './components/Sessao-simples-direita';
import SessaoSimplesEsquerda from './components/Sessao-simples-esquerda';
import Sobre from './components/Sobre';


function App() {
  return (
    <>
      <Header />
      <Sobre />
      <SessaoSimpleDireita
        img={'/13.jpg'}
        titulo={'Formação acadêmica e terapêutica'}
        bg="#f5f5f5"
        texto="Neste caminho, graduei-me na Pedagogia, fiz pós-graduação em Psicopedagogia e tenho formação Teórica em Psicanálise. Em meu 
        trabalho clínico, adoto os cursos de formação na área de Terapias Integrativas, a saber: Apometria Clínica, Antropologia da Mulher 
        (Roda de Mulheres), Barra de Access, Contoterapia, Cristalterapia, Cromoterapia, Hipnoterapia, Radiestesia e Regressão."
      />
      <SessaoSimplesEsquerda
        img={'/8.jpg'}
        titulo={"Minha trajetória Profissional"}
        texto="Iniciei minha vida profissional como professora alfabetizadora, estimulando meus alunos a ter o gosto pela leitura e pela 
        escrita. Em paralelo ao trabalho pedagógico, atuei em consultório com práticas psicopedagógicas. Durante esse percurso, me formei 
        em Terapias Integrativas. O resultado foi tão positivo que tive o desejo de proporcionar este bem para outras pessoas. Iniciei, assim, 
        minha jornada em busca de qualificação nessa área, à qual me dedico há mais de 20 anos. "
      />
      <Footer />
    </>
  );
}

export default App;
