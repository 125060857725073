import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Terapias from './terapias.js';
import Eventos from './eventos.js';
import Casos from './casos.js';
import Conteudo from './conteudo.js';
import Contato from './contato.js';

const router = createBrowserRouter([
  {
  path: "/",
  element: <App/>
  },
  {
    path: "/terapias",
    element: <Terapias />
  },
  {
    path: "/eventos",
    element: <Eventos />
  },
  {
    path: "/casos",
    element: <Casos />
  },
  {
    path: "/conteudo",
    element: <Conteudo />
  },
  {
    path: "/contato",
    element: <Contato />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);